var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm.title ? _c('span', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._t("title")], 2), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_vm.props_input.type === 'checkbox' && _vm.value !== undefined ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputData,
      expression: "inputData"
    }],
    staticClass: "input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.inputData) ? _vm._i(_vm.inputData, null) > -1 : _vm.inputData
    },
    on: {
      "input": _vm.input,
      "change": function ($event) {
        var $$a = _vm.inputData,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.inputData = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.inputData = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.inputData = $$c;
        }
      }
    }
  }, 'input', _vm.props_input, false)) : _vm.props_input.type === 'radio' && _vm.value !== undefined ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputData,
      expression: "inputData"
    }],
    staticClass: "input",
    attrs: {
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.inputData, null)
    },
    on: {
      "input": _vm.input,
      "change": function ($event) {
        _vm.inputData = null;
      }
    }
  }, 'input', _vm.props_input, false)) : _vm.value !== undefined ? _c('input', _vm._b({
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputData,
      expression: "inputData"
    }],
    staticClass: "input",
    attrs: {
      "type": _vm.props_input.type
    },
    domProps: {
      "value": _vm.inputData
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.inputData = $event.target.value;
      }, _vm.input]
    }
  }, 'input', _vm.props_input, false)) : _vm._e(), _vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }