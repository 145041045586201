<template>
    <v-data-table :items="items" :headers="headers" hide-default-header hide-default-footer :class="className">
        <template v-slot:[`item.left`]>
            <v-icon :disabled="upDisabled" @click="setItemUp">mdi-arrow-up</v-icon>
            <v-icon :disabled="downDisabled" @click="setItemDown">mdi-arrow-down</v-icon>
        </template>
        <template v-slot:[`item.right`]>
            <slot name="right" />
        </template>
    </v-data-table>
</template>

<script>
export default {
    props: ["value", "selected", "className"],
    data() {
        return {
            items: [{}],
            headers: [
                { value: "left", align: "left" },
                { value: "right", align: "right" },
            ],
        };
    },
    computed: {
        selectedItem() {
            const [item] = this.selected || [];
            return item;
        },
        selectedIndex() {
            return this.value?.findIndex((item) => item == this.selectedItem);
        },
        upDisabled() {
            return this.selectedItem ? this.selectedIndex < 1 : true;
        },
        downDisabled() {
            return this.selectedItem ? this.selectedIndex > this.value?.length - 2 : true;
        },
    },
    methods: {
        setItemUp() {
            const { upDisabled, selectedItem: item, selectedIndex: index } = this;
            if (!upDisabled) {
                const array = [...this.value];
                array[index] = array[index - 1];
                array[index - 1] = item;
                this.$emit("input", array);
            }
        },
        setItemDown() {
            const { downDisabled, selectedItem: item, selectedIndex: index } = this;
            if (!downDisabled) {
                const array = [...this.value];
                array[index] = array[index + 1];
                array[index + 1] = item;
                this.$emit("input", array);
            }
        },
    },
};
</script>
