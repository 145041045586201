<template>
    <div>
        <!-- 정보입력 -->
        <div class="join-row-wrap">
            <!-- 정보입련: 일반 -->
            <div v-if="isSupporter" class="join-row join-row--form">
                <div class="join-caption join-caption--center">
                    <h3 class="text-20 text-lg-18">공개정보 <small>(선택입력)</small></h3>
                </div>
                <div role="table" class="join-table">
                    <div role="rowgroup">
                        <form-row title="닉네임">
                            <input class="input" type="text" name="nickname" v-model="user.nickname" @input="input" />
                        </form-row>
                        <form-row title="연락처">
                            <div class="form-tel">
                                <input class="input" type="tel" name="phone1" v-model="user.phone1" @input="input" maxlength="4" />
                                <span class="text"></span>
                                <input class="input" type="tel" name="phone2" v-model="user.phone2" @input="input" maxlength="4" />
                                <span class="text"></span>
                                <input class="input" type="tel" name="phone3" v-model="user.phone3" @input="input" maxlength="4" />
                            </div>
                        </form-row>
                        <form-row title="이메일">
                            <div class="form-email">
                                <input class="input" type="text" name="email1" v-model="user.email1" />
                                <span class="text">@</span>
                                <input class="input" type="text" name="email2" v-model="user.email2" />
                                <select v-model="user.email3" class="select" @change="user.email2 = user.email3">
                                    <option :value="null">직접입력</option>
                                    <option :value="`naver.com`">naver.com</option>
                                    <option :value="`daum.net`">daum.net</option>
                                    <option :value="`google.com`">google.com</option>
                                </select>
                            </div>
                        </form-row>
                        <form-row title="전문가 소개">
                            <textarea v-model="user.summary" class="rounded-0" @input="input" :placeholder="`기업에서 조회하는 전문가 검색 섹션에 표시되는 부분입니다.\n전문분야 및 경력사항을 소개형태로 50자 이상 작성 부탁드립니다.`" />
                        </form-row>
                        <form-row title="전문가 사진">
                            <v-file-input v-model="user.avatar" @input="input" dense hide-details outlined class="rounded-0" accept="image/*" />
                        </form-row>
                        <form-row title="정보공개동의">
                            <v-switch label="연락처노출" v-model="user.isContactDisplayed" inset class="ma-0 ml-1" @input="input" />
                        </form-row>
                    </div>
                </div>
            </div>

            <form-description v-if="isSupporter" v-model="user" />
        </div>

        <!-- 하단버튼 -->
        <div class="bottom-button">
            <button class="button button--border-primary" @click="cancel()">
                <span>다음에 하기</span>
            </button>
            <button class="button button--primary" @click="submit()">
                <span>저장하기</span>
            </button>
        </div>

        <daum-postcode ref="daum-postcode" @change="({ postcode, address }) => ((user.postcode = postcode) || true) && ((user.address1 = address) || true)" />
    </div>
</template>

<script>
import dayjs from "dayjs";
import { INDIVIDUAL_KINDS } from "@/assets/variables";

import FormRow from "../form-row.vue";
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import FormDescription from "../form-description.vue";

const initialUser = {
    kind: INDIVIDUAL_KINDS.ELSE.value,
    affiliation: null,
    department: null,
    residentNumber: null,
    residentNumber1: null,
    residentNumber2: null,
    postcode: null,
    address1: null,
    address2: null,
    _areas: [],
    areas_manual: {},
    summary: null,
    _avatar: null,
    isContactDisplayed: null,
};

const kinds = [
    { value: INDIVIDUAL_KINDS.ELSE.value, text: "일반인" },
    { value: INDIVIDUAL_KINDS.SUPPORTER.value, text: "전문가" },
];

const regions = ["서울특별시", "부산광역시", "대구광역시", "인천광역시", "광주광역시", "대전광역시", "울산광역시", "세종특별자치시", "경기도", "강원도", "충청북도", "충청남도", "전라북도", "전라남도", "경상북도", "경상남도", "제주특별자치도"];

export default {
    components: {
        FormRow,
        DaumPostcode,
        FormDescription,
    },
    props: ["value", "areas"],
    data() {
        return {
            user: { ...initialUser, ...this.$props.value } || { ...initialUser },

            kinds,
            regions,
        };
    },
    computed: {
        isSupporter() {
            return this.user?.kind == INDIVIDUAL_KINDS.SUPPORTER.value;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
        "user.email"() {
            this.$emit("input", this.user);
        },
        "user.email1"() {
            this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}` : null);
        },
        "user.email2"() {
            this.$set(this.user, "email", this.user.email1 && this.user.email2 ? `${this.user.email1}@${this.user.email2}` : null);
        },

        "user.phone"() {
            this.$emit("input", this.user);
        },
        "user.phone1"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
        "user.phone2"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },
        "user.phone3"() {
            this.$set(this.user, "phone", this.user.phone1 && this.user.phone2 && this.user.phone3 ? `${this.user.phone1}-${this.user.phone2}-${this.user.phone3}` : null);
        },

        "user.bornAt"() {
            this.$emit("input", this.user);
        },
        "user.residentNumber1"() {
            if (this.user.residentNumber1?.length == 6) {
                const year = dayjs().format("YY");
                const [yy, mm, dd] = this.user.residentNumber1.match(/.{1,2}/g);

                if (this.user.residentNumber2?.length > 0) {
                    const criterion = this.user.residentNumber2[0];
                    this.user.bornAt = [yy.padStart(4, +criterion < 3 ? "19" : "20"), mm, dd].join("-");
                } else this.user.bornAt = [yy.padStart(4, +yy > +year ? "19" : "20"), mm, dd].join("-");
            } else this.user.bornAt = null;

            this.user.residentNumber = `${this.user.residentNumber1}-${this.user.residentNumber2}`;
        },
        "user.residentNumber2"() {
            if (this.user.residentNumber1?.length == 6) {
                const year = dayjs().format("YY");
                const [yy, mm, dd] = this.user.residentNumber1.match(/.{1,2}/g);

                if (this.user.residentNumber2?.length > 0) {
                    const criterion = this.user.residentNumber2[0];
                    this.user.bornAt = [yy.padStart(4, +criterion < 3 ? "19" : "20"), mm, dd].join("-");
                } else this.user.bornAt = [yy.padStart(4, +yy > +year ? "19" : "20"), mm, dd].join("-");
            } else this.user.bornAt = null;

            this.user.residentNumber = `${this.user.residentNumber1}-${this.user.residentNumber2}`;
        },
    },
    methods: {
        sync() {
            this.user = { ...this.value };
        },
        input() {
            this.$emit("input", this.user);
        },
        onSearchPostcode() {
            this.$refs["daum-postcode"].open();
        },
        cancel() {
            this.$router.push("/");
        },

        submit() {
            if (this.validate()) this.$emit("save", this.user);
        },

        validate() {
            try {
                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },
    },
};
</script>
