var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "join-row join-row--form"
  }, [_vm._m(0), _c('v-data-table', {
    staticClass: "modify-description-table",
    attrs: {
      "item-key": "tempId",
      "items": _vm.items,
      "headers": _vm.headers,
      "show-select": "",
      "single-select": "",
      "hide-default-footer": "",
      "disable-sort": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: `item.index`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(_vm._s(_vm.getItemIndex(item)))];
      }
    }, {
      key: `item.term`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-select', {
          staticClass: "rounded-0",
          attrs: {
            "value": item.term,
            "items": _vm.terms,
            "hide-details": "",
            "dense": ""
          },
          on: {
            "input": function (value) {
              return _vm.setItemValue(item, value, true);
            }
          }
        })];
      }
    }, {
      key: `item.description`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-text-field', {
          staticClass: "rounded-0",
          attrs: {
            "value": item.description,
            "hide-details": "",
            "dense": ""
          },
          on: {
            "input": function (value) {
              return _vm.setItemValue(item, value);
            }
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          on: {
            "click": function ($event) {
              return _vm.removeItem(item);
            }
          }
        }, [_vm._v("mdi-minus-circle-outline")])];
      }
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('table-action-footer', {
    attrs: {
      "selected": _vm.selected,
      "className": "mb-4 no-border-top"
    },
    model: {
      value: _vm.items,
      callback: function ($$v) {
        _vm.items = $$v;
      },
      expression: "items"
    }
  }, [_c('v-icon', {
    attrs: {
      "slot": "right"
    },
    on: {
      "click": _vm.addItem
    },
    slot: "right"
  }, [_vm._v("mdi-plus-circle-outline")])], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "join-caption join-caption--center"
  }, [_c('h3', {
    staticClass: "text-20 text-lg-18"
  }, [_vm._v("보유기술정보 "), _c('small', [_vm._v("(선택입력)")])])]);

}]

export { render, staticRenderFns }