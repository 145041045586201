var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    class: _vm.className,
    attrs: {
      "items": _vm.items,
      "headers": _vm.headers,
      "hide-default-header": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `item.left`,
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "disabled": _vm.upDisabled
          },
          on: {
            "click": _vm.setItemUp
          }
        }, [_vm._v("mdi-arrow-up")]), _c('v-icon', {
          attrs: {
            "disabled": _vm.downDisabled
          },
          on: {
            "click": _vm.setItemDown
          }
        }, [_vm._v("mdi-arrow-down")])];
      },
      proxy: true
    }, {
      key: `item.right`,
      fn: function () {
        return [_vm._t("right")];
      },
      proxy: true
    }], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }