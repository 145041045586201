var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "join-row-wrap"
  }, [_vm.isSupporter ? _c('div', {
    staticClass: "join-row join-row--form"
  }, [_vm._m(0), _c('div', {
    staticClass: "join-table",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('form-row', {
    attrs: {
      "title": "닉네임"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.nickname,
      expression: "user.nickname"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "name": "nickname"
    },
    domProps: {
      "value": _vm.user.nickname
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "nickname", $event.target.value);
      }, _vm.input]
    }
  })]), _c('form-row', {
    attrs: {
      "title": "연락처"
    }
  }, [_c('div', {
    staticClass: "form-tel"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.phone1,
      expression: "user.phone1"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "name": "phone1",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.user.phone1
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "phone1", $event.target.value);
      }, _vm.input]
    }
  }), _c('span', {
    staticClass: "text"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.phone2,
      expression: "user.phone2"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "name": "phone2",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.user.phone2
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "phone2", $event.target.value);
      }, _vm.input]
    }
  }), _c('span', {
    staticClass: "text"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.phone3,
      expression: "user.phone3"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "name": "phone3",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.user.phone3
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "phone3", $event.target.value);
      }, _vm.input]
    }
  })])]), _c('form-row', {
    attrs: {
      "title": "이메일"
    }
  }, [_c('div', {
    staticClass: "form-email"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.email1,
      expression: "user.email1"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "name": "email1"
    },
    domProps: {
      "value": _vm.user.email1
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "email1", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("@")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.email2,
      expression: "user.email2"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "name": "email2"
    },
    domProps: {
      "value": _vm.user.email2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "email2", $event.target.value);
      }
    }
  }), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.email3,
      expression: "user.email3"
    }],
    staticClass: "select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.user, "email3", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        _vm.user.email2 = _vm.user.email3;
      }]
    }
  }, [_c('option', {
    domProps: {
      "value": null
    }
  }, [_vm._v("직접입력")]), _c('option', {
    domProps: {
      "value": `naver.com`
    }
  }, [_vm._v("naver.com")]), _c('option', {
    domProps: {
      "value": `daum.net`
    }
  }, [_vm._v("daum.net")]), _c('option', {
    domProps: {
      "value": `google.com`
    }
  }, [_vm._v("google.com")])])])]), _c('form-row', {
    attrs: {
      "title": "전문가 소개"
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.summary,
      expression: "user.summary"
    }],
    staticClass: "rounded-0",
    attrs: {
      "placeholder": `기업에서 조회하는 전문가 검색 섹션에 표시되는 부분입니다.\n전문분야 및 경력사항을 소개형태로 50자 이상 작성 부탁드립니다.`
    },
    domProps: {
      "value": _vm.user.summary
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "summary", $event.target.value);
      }, _vm.input]
    }
  })]), _c('form-row', {
    attrs: {
      "title": "전문가 사진"
    }
  }, [_c('v-file-input', {
    staticClass: "rounded-0",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "accept": "image/*"
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.avatar,
      callback: function ($$v) {
        _vm.$set(_vm.user, "avatar", $$v);
      },
      expression: "user.avatar"
    }
  })], 1), _c('form-row', {
    attrs: {
      "title": "정보공개동의"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 ml-1",
    attrs: {
      "label": "연락처노출",
      "inset": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.user.isContactDisplayed,
      callback: function ($$v) {
        _vm.$set(_vm.user, "isContactDisplayed", $$v);
      },
      expression: "user.isContactDisplayed"
    }
  })], 1)], 1)])]) : _vm._e(), _vm.isSupporter ? _c('form-description', {
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "bottom-button"
  }, [_c('button', {
    staticClass: "button button--border-primary",
    on: {
      "click": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('span', [_vm._v("다음에 하기")])]), _c('button', {
    staticClass: "button button--primary",
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_c('span', [_vm._v("저장하기")])])]), _c('daum-postcode', {
    ref: "daum-postcode",
    on: {
      "change": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        return ((_vm.user.postcode = postcode) || true) && ((_vm.user.address1 = address) || true);
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "join-caption join-caption--center"
  }, [_c('h3', {
    staticClass: "text-20 text-lg-18"
  }, [_vm._v("공개정보 "), _c('small', [_vm._v("(선택입력)")])])]);

}]

export { render, staticRenderFns }