<template>
    <div role="row">
        <div role="columnheader">
            <span v-if="title">{{ title }}</span>
            <slot name="title" />
        </div>
        <div role="cell">
            <input v-if="value !== undefined" v-bind="props_input" v-model="inputData" @input="input" class="input" />
            <slot />
        </div>
    </div>
</template>

<script>
const props = {
    class: "input",
    type: "text",
};

export default {
    props: ["title", "value", "inputProps"],
    computed: {
        props_input() {
            return this.inputProps ? { ...props, ...this.inputProps } : props;
        },
    },
    data() {
        return {
            inputData: this.value || "",
        };
    },
    methods: {
        input() {
            this.$emit("input", this.inputData);
        },
    },
};
</script>

<style></style>
