<template>
    <div class="join-row join-row--form">
        <div class="join-caption join-caption--center">
            <h3 class="text-20 text-lg-18">보유기술정보 <small>(선택입력)</small></h3>
        </div>

        <v-data-table v-model="selected" item-key="tempId" :items="items" :headers="headers" show-select single-select hide-default-footer disable-sort :items-per-page="-1" class="modify-description-table">
            <template v-slot:[`item.index`]="{ item }">{{ getItemIndex(item) }}</template>
            <template v-slot:[`item.term`]="{ item }">
                <v-select :value="item.term" :items="terms" @input="(value) => setItemValue(item, value, true)" hide-details dense class="rounded-0" />
            </template>
            <template v-slot:[`item.description`]="{ item }">
                <v-text-field :value="item.description" @input="(value) => setItemValue(item, value)" hide-details dense class="rounded-0" />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="removeItem(item)">mdi-minus-circle-outline</v-icon>
            </template>
        </v-data-table>
        <table-action-footer v-model="items" :selected="selected" className="mb-4 no-border-top">
            <v-icon slot="right" @click="addItem">mdi-plus-circle-outline</v-icon>
        </table-action-footer>
    </div>
</template>

<script>
import { EXPERT_DESCRIPTION_TERMS as terms, USER_TYPES } from "@/assets/variables";

import TableActionFooter from "./table-action-footer.vue";

const headers = [
    { text: "#", value: "index", width: 40 },
    { text: "카테고리", value: "term", width: 200 },
    { text: "상세", value: "description" },
    { text: "", value: "actions", width: 50, align: "right" },
];

export default {
    components: {
        TableActionFooter,
    },
    props: {
        value: {
            expertDescriptions: Array,
        },
    },
    computed: {
        isSupporter() {
            return this.value?.type == USER_TYPES.INDIVIDUAL;
        },
        isOrganization() {
            return this.value?.type == USER_TYPES.ORGANIZATION;
        },
        terms() {
            if (this.isSupporter) return terms.filter((term) => !["기술정보", "제품", "서비스"].includes(term));
            if (this.isOrganization) return terms.filter((term) => !["학위", "논문"].includes(term));
        },
        title() {
            if (this.isOrganization) return "제품 및 기술정보 수정";
            if (this.isSupporter) return "보유기술 정보 수정";
        },
    },
    data() {
        return {
            headers,
            items: [],
            selected: [],
        };
    },
    methods: {
        resetItems() {
            this.items = (this.value?.expertDescriptions || [])?.map((description, i) => ({
                tempId: Math.random(),
                ...description,
            }));
        },
        addItem() {
            this.items.push({ tempId: Math.random(), term: null, description: null });
            this.sync();
        },
        removeItem({ tempId }) {
            this.items = this.items.filter((item) => item.tempId !== tempId);
            this.sync();
        },
        setItemValue({ tempId }, newValue, isTerm = false) {
            const target = this.items.find((item) => item.tempId == tempId);
            if (isTerm) target.term = newValue;
            else target.description = newValue;

            this.sync();
        },
        sync() {
            const { value, items: expertDescriptions } = this;
            this.$emit("input", { ...value, expertDescriptions });
        },
        getItemIndex({ tempId }) {
            const index = this.items.findIndex((item) => item.tempId == tempId);
            return index + 1;
        },
    },
    mounted() {
        this.resetItems();
    },
    watch: {
        dialog() {
            if (this.dialog) this.resetItems();
        },
        items() {
            this.sync();
        },
    },
};
</script>

<style lang="scss">
.no-border-top {
    &,
    * {
        border-top: none !important;
    }
}
.modify-description-table {
    thead th {
        background-color: #f7f7f7 !important;
    }
}
</style>
